<template>
  <div class="animated fadeIn">
    <!-- Count Data -->
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row v-if="$name()">
      <b-col sm="12">
        <div>
          <b-alert show dismissible>
            You are logged now, hello {{ $name() }}!
          </b-alert>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="this.password_expired">
        <b-col sm="12">
          <div>
            <b-alert show variant="warning" dismissible>
              <strong>Password Kamu sudah expired harap update kembali password kamu.</strong>
            </b-alert>
          </div>
        </b-col>
      </b-row>
    <b-row>
      <b-col sm="6" lg="3" v-if="$can('see_new_user_total')">
        <b-card :no-body="true" footer-class="px-3 py-2">
          <b-card-body class="p-3 clearfix">
            <i class="fa fa-user-plus bg-primary p-3 font-2xl mr-3 float-left"></i>
            <div class="h5 text-primary mb-0 mt-2">{{ this.newUser }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">Total New User</div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col sm="6" lg="3" v-if="$can('see_new_user_target')">
        <b-card :no-body="true" footer-class="px-3 py-2">
          <b-card-body class="p-3 clearfix">
            <i class="fa fa-laptop bg-info p-3 font-2xl mr-3 float-left"></i>
            <div class="h5 text-info mb-0 mt-2">{{ this.userTarget }} %</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">New User Target</div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col sm="6" lg="3" v-if="$can('see_user_total')">
        <b-card :no-body="true" footer-class="px-3 py-2">
          <b-card-body class="p-3 clearfix">
            <i class="fa fa-users bg-warning p-3 font-2xl mr-3 float-left"></i>
            <div class="h5 text-warning mb-0 mt-2">{{ this.totalUser }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">Total User</div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col sm="6" lg="3" v-if="$can('see_active_user_total')">
        <b-card :no-body="true" footer-class="px-3 py-2">
          <b-card-body class="p-3 clearfix">
            <i class="fa fa-check-circle bg-danger p-3 font-2xl mr-3 float-left"></i>
            <div class="h5 text-danger mb-0 mt-2">{{ this.activeUser }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">Total Active User</div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="$can('usership')">
      <b-col sm="12">
        <b-card>
          <b-row class="mb-5">
            <b-col>
              <div class="text-center">
                <h3>SUMMARY</h3>
              </div>
            </b-col>
          </b-row>
          <b-row class="text-center mb-5">
            <b-col sm="6" lg="4">
              <div class="h5 text-primary mb-0 mt-2">{{ this.summary.total }}</div>
              <div class="text-muted text-uppercase font-weight-bold font-xs">Total User</div>
            </b-col>
            <b-col sm="6" lg="4">
              <div class="h5 text-primary mb-0 mt-2">{{ this.summary.active }}</div>
              <div class="text-muted text-uppercase font-weight-bold font-xs">Active User</div>
            </b-col>
            <b-col sm="6" lg="4">
              <div class="h5 text-primary mb-0 mt-2">{{ this.summary.new }}</div>
              <div class="text-muted text-uppercase font-weight-bold font-xs">New User</div>
            </b-col>
          </b-row>
          <b-row class="text-center">
            <b-col sm="12" lg="6">
              <div class="h5 text-primary mb-0 mt-2">{{ this.summary.silent }}</div>
              <div class="text-muted text-uppercase font-weight-bold font-xs">Silent User</div>
            </b-col>
            <b-col sm="12" lg="6">
              <div class="h5 text-primary mb-0 mt-2">{{ this.summary.dead }}</div>
              <div class="text-muted text-uppercase font-weight-bold font-xs">Dead User</div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="$can('usership')">
      <b-col sm="12">
        <b-card>
          <b-row class="text-center mb-5">
            <b-col sm="12" lg="6">
              <div class="h5 text-primary mb-0 mt-2">{{ this.summary.frequency_buy }}</div>
              <div class="text-muted text-uppercase font-weight-bold font-xs">Total Frequency Buy</div>
            </b-col>
            <b-col sm="12" lg="6">
              <div class="h5 text-primary mb-0 mt-2">{{ this.summary.frequency_sell }}</div>
              <div class="text-muted text-uppercase font-weight-bold font-xs">Total Frequency Sell</div>
            </b-col>
          </b-row>
          <b-row class="text-center">
            <b-col sm="12" lg="6">
              <div class="h5 text-primary mb-0 mt-2">{{ this.summary.average_number }}</div>
              <div class="text-muted text-uppercase font-weight-bold font-xs">Average Check per Number of Transaction</div>
            </b-col>
            <b-col sm="12" lg="6">
              <div class="h5 text-primary mb-0 mt-2">{{ this.summary.average_gram }}</div>
              <div class="text-muted text-uppercase font-weight-bold font-xs">Average Check Gram per Active User</div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- User Card -->
    <b-row v-if="$can('see_new_user_by_age')">
      <b-col md="12">
        <b-card header="User Diagrams">
          <a href="dashboard-detail-user" :style="{ color: 'black' }">See more Details</a>
          <br>
          <br>
          <b-row>
            <b-col sm="12" lg="6" v-if="$can('see_new_user_by_age')">
              <b-row>
                <b-col sm="12">
                  <h4 style="text-align:center">Users Based On Age</h4>
                </b-col>
              </b-row>
              <bar-user-age chartId="chart-bar-01"/>
            </b-col>
            <b-col sm="12" lg="6" v-if="$can('see_new_user_by_gender')">
              <b-row>
                <b-col sm="12">
                  <h4 style="text-align:center">Users Based On Gender</h4>
                </b-col>
              </b-row>
            <doughnut-user-gender chartId="chart-doughnut-01"/>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- Transaction Card -->
    <b-row v-if="$can('see_transaction_buying_by_gender')">
      <b-col md="12">
        <b-card header="Transaction Diagrams">
          <a href="dashboard-detail-transaction" :style="{ color: 'black' }">See more Details</a>
          <br>
          <br>
          <b-row>
            <b-col sm="12" lg="6" v-if="$can('see_transaction_buying_by_gender')">
              <b-row>
                <b-col sm="12">
                  <h4 style="text-align:center">Buying Transaction Based On Gender</h4>
                </b-col>
              </b-row>
              <Line-Buying chartId="chart-buying-01"/>
            </b-col>
            <b-col sm="12" lg="6" v-if="$can('see_transaction_selling_by_gender')">
              <b-row>
                <b-col sm="12">
                  <h4 style="text-align:center">Selling Transaction Based On Gender</h4>
                </b-col>
              </b-row>
            <Line-Selling chartId="chart-selling-01"/>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- Revenue -->
    <b-row v-if="$can('see_transaction_by_time')">
      <b-col md="12">
        <b-card header="Revenue">
          <b-row>
            <b-col sm="12">
              <form @submit.prevent="revenuePost">
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <i class="fa fa-calendar"></i>
                    </b-input-group-prepend>
                    <range-picker :start="startDate" :end="endDate" @picker="revenueChange"></range-picker>
                    <!-- Attach Right button -->
                    <b-input-group-append>
                      <b-button type="submit" variant="primary" >Submit</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </form>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="bg-primary">
                <b-card-body class="pb-0">
                  <h4 class="mb-0">{{ this.defaultRevenue.daily | currency }}</h4>
                  <p>Today Revenue</p>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col>
              <b-card no-body class="bg-primary">
                <b-card-body class="pb-0">
                  <h4 class="mb-0">{{ this.defaultRevenue.week | currency }}</h4>
                  <p>Last Week Revenue</p>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col>
              <b-card no-body class="bg-primary">
                <b-card-body class="pb-0">
                  <h4 class="mb-0">{{ this.defaultRevenue.month | currency }}</h4>
                  <p>Last Month Revenue</p>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="bg-primary">
                <b-card-body class="pb-0">
                  <div  v-if="this.resultRevenue.total == null">
                    <h4 class="mb-0">{{ this.defaultRevenue.total | currency }}</h4>
                    <p>Total Revenue From {{ this.defaultRevenue.current_startDate }} to {{ this.defaultRevenue.current_endDate }}</p>
                  </div>
                  <div v-else>
                    <h4 class="mb-0">{{ this.resultRevenue.total | currency }}</h4>
                    <p>Total Revenue From {{ this.resultRevenue.current_startDate}} to {{ this.resultRevenue.current_endDate }}</p>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- Tax Card -->
    <b-row v-if="$can('see_revenue')">
      <b-col md="12">
        <b-card header="Tax">
          <b-row>
            <b-col sm="12">
              <form @submit.prevent="taxPost">
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <i class="fa fa-calendar"></i>
                    </b-input-group-prepend>
                    <range-picker :start="startDate" :end="endDate" @picker="taxChange"></range-picker>
                    <b-input-group-append>
                      <b-button type="submit" variant="primary" >Submit</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </form>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="bg-info">
                <b-card-body class="pb-0">
                  <div  v-if="this.resultTax.total_tax == null">
                    <h4 class="mb-0">{{ this.defaultTax.total_tax | currency }}</h4>
                    <p>Total Tax From {{ this.defaultTax.current_startDate }} to {{ this.defaultTax.current_endDate }}</p>
                  </div>
                  <div v-else>
                    <h4 class="mb-0">{{ this.resultTax.total_tax | currency }}</h4>
                    <p>Total Tax From {{ this.resultTax.current_startDate}} to {{ this.resultTax.current_endDate }}</p>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- Reedem Card -->
    <b-row v-if="$can('see_ewallet_balance_total')">
      <b-col md="12">
        <b-card header="Cetak Emas">
          <b-row>
            <b-col>
              <b-card no-body class="bg-warning">
                <b-card-body class="pb-0">
                  <h4 class="mb-0">{{ this.redeem }} gram</h4>
                  <p>Total Cetak Emas</p>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- E-wallet Balance Card -->
    <b-row v-if="$can('see_ewallet_balance')">
      <b-col md="12">
        <b-card header="E-Wallet Balance">
          <b-row v-if="$can('see_ewallet_balance_total')">
            <b-col>
              <b-card no-body class="bg-success">
                <b-card-body class="pb-0">
                  <h4 class="mb-0">{{ this.walletBalance | currency }}</h4>
                  <p>Total E-wallet Balance</p>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" lg="12" md="12" v-if="$can('see_ewallet_balance_by_age')">
              <b-row>
                <b-col sm="12">
                  <h4 style="text-align:center">E-wallet Balance Based On Age</h4>
                </b-col>
              </b-row>
              <Bar-Ewallet-Up chartId="chart-ewallet-02"/>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- E-wallet Balance Card -->
    <b-row v-if="$can('see_gold_balance')">
      <b-col md="12" >
        <b-card header="Gold Balance">
          <b-row v-if="$can('see_gold_balance_total')">
            <b-col>
              <b-card no-body class="bg-success">
                <b-card-body class="pb-0">
                  <h4 class="mb-0">{{ this.goldBalance }}</h4>
                  <p>Total Gold Balance</p>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="$can('see_gold_balance_by_gender')">
            <b-col sm="12">
              <p>Select Date</p>
              <form @submit.prevent="goldPost">
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <i class="fa fa-calendar"></i>
                    </b-input-group-prepend>
                    <range-picker :start="startDate" :end="endDate" @picker="goldChange"></range-picker>
                    <!-- Attach Right button -->
                    <b-input-group-append>
                      <b-button type="submit" variant="primary">Submit</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </form>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <Bar-Daily-Gold chartId="chart-daily-01" ref="goldDaily"/>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="$can('see_user_total_by_location')">
      <b-col>
        <b-card header="User Location">
          <b-row>
            <b-col sm="12">
              <form @submit.prevent="exportUsers">
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <i class="fa fa-calendar"></i>
                    </b-input-group-prepend>
                    <range-picker :start="startDate" :end="endDate" @picker="locationChange"></range-picker>
                    <b-input-group-append>
                      <b-button type="submit" variant="success" >Export</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </form>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="$can('see_user_total_by_country')">
              <Region chartId="chart-region-01" />
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="$can('see_user_total_by_city')">
              <Bar-User-Location chartId="chart-location-01"/>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DoughnutUserGender from './charts/DoughnutUserGender'
import BarUserAge from './charts/BarUserAge'
import LineSelling from './charts/LineSelling'
import LineBuying from './charts/LineBuying'
import BarEwalletUp from './charts/BarEwalletUp'
import BarDailyGold from './charts/BarDailyGold'
import BarUserLocation from './charts/BarUserLocation'
import Region from './charts/RegionChart'
import { mapGetters } from 'vuex'

let revenueStart = '';
let revenueEnd = '';

let taxStart = '';
let taxEnd = '';

let goldStart = '';
let goldEnd = '';

let locationStart = '';
let locationEnd = '';
export default {
  name: 'dashboard',
  components: {
    DoughnutUserGender,
    BarUserAge,
    LineSelling,
    LineBuying,
    BarEwalletUp,
    BarDailyGold,
    BarUserLocation,
    Region,
  },
  computed: {
    ...mapGetters([
      'password_expired'
    ])
  },
  data: function () {
    return {
      accessPermission: [],
      totalUser: '',
      activeUser: '',
      newUser: '',
      userTarget: '',
      dismissSecs: 10,
      isLoading: false,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      taxDate: '',
      revenueDate: '',
      goldDate: '',
      startDate: '',
      endDate: '',
      gold: {
        start_from: '',
        end_to: ''
      },
      redeem: '',
      walletBalance: '',
      goldBalance: '',
      defaultTax: {
        current_startDate: '',
        current_endDate: '',
        total_tax: null
      },
      defaultRevenue: {
        current_startDate: '',
        current_endDate: '',
        total: null,
        daily: '',
        week: '',
        month: ''
      },
      resultTax: {
        current_startDate: '',
        current_endDate: '',
        total_tax: null
      },
      resultRevenue: {
        current_startDate: '',
        current_endDate: '',
        total: null,
      },
      selected: 'Month',
      errors: {
        code: '',
        status: '',
        headers: ''
      },
      summary: {
        total: '',
        active: '',
        new: '',
        silent: '',
        dead: '',
        frequency_buy: '',
        frequency_sell: '',
        average_number: '',
        average_gram: ''
      }
    }
  },
  created() {

    this.$http.get(`users`)
      .then((result) => {
        this.totalUser = result.data.totalUser;
        this.activeUser = result.data.activeUser;
        this.newUser = result.data.newUser;
        this.userTarget = result.data.userTarget;
      })
      .catch((error) => {
        if (error.response) {
          this.errors.status = error.response.data.meta.code;
          this.errors.code = error.response.status;
          this.errors.headers = error.response.headers;
        }
      })

    this.$http.get(`tax`)
    .then((result) => {
      this.defaultTax.total_tax = result.data.total_tax;
      this.defaultTax.current_startDate = result.data.current_startDate;
      this.defaultTax.current_endDate = result.data.current_endDate;
    })
    .catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })

    this.$http.get(`revenue`)
    .then((result) => {
      this.defaultRevenue.total = result.data.total;
      this.defaultRevenue.daily = result.data.daily;
      this.defaultRevenue.week = result.data.week;
      this.defaultRevenue.month = result.data.month;
      this.defaultRevenue.current_startDate = result.data.current_startDate;
      this.defaultRevenue.current_endDate = result.data.current_endDate;
    })
    .catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })

    this.$http.get(`redeem`)
    .then((result) => {
      this.redeem = result.data.redeem;
    })
    .catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })

    this.$http.get(`wallet-balance`)
    .then((result) => {
      this.walletBalance = result.data.ewallet;
    })
    .catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })

    this.$http.get(`gold-balance`)
    .then((result) => {
      this.goldBalance = result.data.gold;
    })
    .catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })
    // alert("can show this")
    this.$http.get(`bma/summary`)
      .then((result) => {
        this.summary.total = result.data.data.total;
        this.summary.active = result.data.data.active;
        this.summary.new = result.data.data.new;
        this.summary.silent = result.data.data.silent;
        this.summary.dead = result.data.data.dead;
        this.summary.frequency_buy = result.data.data.frequency_buy;
        this.summary.frequency_sell = result.data.data.frequency_sell;
        this.summary.average_number = result.data.data.average_number;
        this.summary.average_gram = result.data.data.average_gram;
      }).catch((error) => {
        if (error.response) {
          this.errors.code = error.response.data.meta.code;
          this.errors.status = error.response.status;
          this.errors.headers = error.response.headers;
        }
      })
  },
  methods: {
    locationChange(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
    },
    exportUsers() {
      this.isLoading = true
      if (this.startDate != '' && this.endDate != '') {
        locationStart = this.startDate;
        locationEnd = this.endDate;
      }else{
        locationEnd = this.$setDate.format('YYYY-MM-DD');
        locationStart = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
      }
      this.$http.get(`users-location/` + locationStart + `/` + locationEnd)
      .then((result) => {
        this.isLoading = false
        const  exportPath = result.data.meta.data;
        window.location.href = exportPath;
      }).catch((error) => {
        if (error.response) {
          this.isLoading = false
          this.errors.code = error.response.status;
          this.errors.status = error.response.data.meta.code;
          this.errors.headers = error.response.headers;
        }
      })
    },
    goldChange(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
    },
    goldPost(){
      if (this.startDate != '' && this.endDate != '') {
        goldStart = this.startDate;
        goldEnd = this.endDate;
      }else{
        goldEnd = this.$setDate.format('YYYY-MM-DD');
        goldStart = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
      }
      this.$refs.goldDaily.postDaily(goldStart, goldEnd);
    },
    taxChange(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate
    },
    taxPost() {
      if (this.startDate != '' && this.endDate != '') {
        taxStart = this.startDate
        taxEnd = this.endDate
      }else{
        taxEnd = this.$setDate.format('YYYY-MM-DD')
        taxStart = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
      }

        this.$http.post(`tax`, {
          start_from: taxStart,
          end_to: taxEnd
        }).then((result) => {
          this.resultTax.total_tax = result.data.total_tax;
          this.resultTax.current_startDate = result.data.current_startDate;
          this.resultTax.current_endDate = result.data.current_endDate;
        }).catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
    },
    revenueChange(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate
    },
    revenuePost() {
      if (this.startDate != '' && this.endDate != '') {
        revenueStart = this.startDate
        revenueEnd = this.endDate
      }else{
        revenueEnd = this.$setDate.format('YYYY-MM-DD')
        revenueStart = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
      }
      this.$http.post(`revenue`, {
        start_from: revenueStart,
        end_to: revenueEnd
      }).then((result) => {
        this.resultRevenue.total = result.data.total;
        this.resultRevenue.current_startDate = result.data.current_startDate;
        this.resultRevenue.current_endDate = result.data.current_endDate;
      }).catch((error) => {
        if (error.response) {
          this.errors.status = error.response.data.meta.code;
          this.errors.code = error.response.status;
          this.errors.headers = error.response.headers;
        }
      })
    },
  }
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
/* Absolute Center Spinner */
.loading {
position: fixed;
z-index: 999;
height: 2em;
width: 2em;
overflow: visible;
margin: auto;
top: 0;
left: 0;
bottom: 0;
right: 0;
}

/* Transparent Overlay */
.loading:before {
content: '';
display: block;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0,0,0,0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
