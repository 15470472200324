<script>
import { HorizontalBar, mixins } from 'vue-chartjs'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'
import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities'

export default {
  components: {
    hexToRgba,
    CustomTooltips
  },
    extends: HorizontalBar,
        mixins: [mixins.reactiveData],
    data() {
        return {
        chartData: '',
        errors: {
          code: '',
          status: '',
          headers: ''
        }
        }
    },
  mounted () {
    // Overwriting base render method with actual data.
    this.renderChart(this.chartData)
  },
  created () {
        
        this.$http.get(`user-location`)
        .then((response) => {
            const label1 = response.data.allCity;
            const val = response.data.cityValue; 
            const LabelData = [];
            const ValueData = [];
            for (let index = 0; index < label1.length; index++) {
              const element = label1[index];
              LabelData.push(element);
            }
            for (let i = 0; i < val.length; i++) {
              const row = val[i];
              ValueData.push(row);
            }
            // console.log(ValueData);
            this.chartData = {
                labels: LabelData,
                datasets: [
                  {
                      label: 'Total User',
                      backgroundColor: '#0d91e3',
                      data:ValueData
                  }
                ]
            },
            {
                responsive: true,
                maintainAspectRatio: true,
                tooltips: {
                enabled: false,
                custom: CustomTooltips,
                intersect: true,
                mode: 'index',
                position: 'nearest',
                callbacks: {
                    labelColor: function (tooltipItem, chart) {
                    return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor }
                    }
                }
                }
            }
        })
        .catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
  },
}
</script>
