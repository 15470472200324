<script>
import { Bar, mixins } from 'vue-chartjs'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'

export default {
  extends: Bar,
  mixins: [mixins.reactiveData],
  data() {
    return {
      chartData: '',
      responseData: '',
      Label: '',
      resultData: null,
      LabelPost:'',
      errors: {
        code: '',
        status: '',
        headers: ''
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData)
  },
  methods: {
    postDaily(start, end) {
      this.$http.post(`gold-day`, {
        start_from: start,
        end_to: end
      })
      .then((result) => {
        this.resultData = result.data.goldcount;
        this.LabelPost = result.data.date;
        this.chartData = {
        labels: this.LabelPost,
        datasets: [
          {
            label: 'Gold Balance Per Day',
            backgroundColor: '#0d91e3',
            data: this.resultData
          }
        ],
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          enabled: false,
          custom: CustomTooltips,
          intersect: true,
          mode: 'index',
          position: 'nearest',
          callbacks: {
            labelColor: function (tooltipItem, chart) {
              return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor }
            }
          }
        }
      }
      })
      .catch((error) => {
        if (error.response) {
          this.errors.status = error.response.data.meta.code;
          this.errors.code = error.response.status;
          this.errors.headers = error.response.headers;
        }
      })
    }
  },
    created () {
    this.$http.get(`gold-day`)
    .then((response) => {
      this.responseData = response.data;
      this.Label = this.responseData.date;
      this.chartData = {
        labels: this.Label,
        datasets: [
          {
            label: 'Gold Balance Per Day',
            backgroundColor: '#0d91e3',
            data: [this.responseData.count]
          }
        ],
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          enabled: false,
          custom: CustomTooltips,
          intersect: true,
          mode: 'index',
          position: 'nearest',
          callbacks: {
            labelColor: function (tooltipItem, chart) {
              return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor }
            }
          }
        }
      }
    })
    .catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })
  },
}
</script>
