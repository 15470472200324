    <script>
    import { Bar, mixins } from 'vue-chartjs'
    import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'

    export default {
    extends: Bar,
    mixins: [mixins.reactiveData],
    data() {
        return {
        chartData: '',
        errors: {
            code: '',
            status: '',
            headers: ''
        }
        }
    },
    mounted () {
        // Overwriting base render method with actual data.
        this.renderChart(this.chartData)
    },
    created () {
            
        this.$http.get(`wallet-age`)
        .then((response) => {
            const responseData = response.data.ewallet;

            const wallet = [];
            for (const key in responseData) {
                if (responseData.hasOwnProperty(key)) {
                const element = responseData[key];
                wallet.push(element);
                }
            }
            this.chartData = {
                labels: ['17 - 20', '21 - 25', '26 - 30', '31 - 35', '36 - 40', '41 - 45', '45 - 50', '50 - 100'],
                datasets: [
                    {
                    label: 'Ages',
                    backgroundColor: ['#0d91e3','#0d26e3', '#19c29d', '#763ecd', '#cd0de3', '#e30d91', '#f87979', '#00d9e0'],
                    data: [wallet[0], wallet[1], wallet[2], wallet[3], wallet[4], wallet[5], wallet[6], wallet[7]]
                    }
                ],
                responsive: true,
                maintainAspectRatio: true,
                tooltips: {
                    enabled: false,
                    custom: CustomTooltips,
                    intersect: true,
                    mode: 'index',
                    position: 'nearest',
                    callbacks: {
                    labelColor: function (tooltipItem, chart) {
                        return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor }
                    }
                    }
                }
            }
        })
        .catch((error) => {
            if (error.response) {
                this.errors.code = error.response.status;
                this.errors.status = error.response.data.meta.code;
                this.errors.headers = error.response.headers;
            }
        })
    },
    }
    </script>
