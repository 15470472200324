<template>
    <div id="regions_div" style="width: 100%; height: 500px;"></div>
</template>

<script>
export default {
    data() {
        return {
            region: "",
            val: "",
            chartData: "",
            errors: {
                code: '',
                status: '',
                headers: ''
            }
        }
    },
    mounted () {
        google.charts.load('current', {
        'packages':['geochart'],
            // Note: you will need to get a mapsApiKey for your project.
            // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
            'mapsApiKey': 'AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY'
        });
        google.charts.setOnLoadCallback(this.drawRegionsMap());
    },
    methods: {
        drawRegionsMap() {
            this.$http.get(`user-region`)
            .then((result) => {
                const region= result.data.regionName[0];
                const val= result.data.regionValue[0];
                var data = google.visualization.arrayToDataTable([
                ['Country', 'Total User'],
                ['Indonesia', 0],
                [region, val]
                ]);

                var options = {};

                var chart = new google.visualization.GeoChart(document.getElementById('regions_div'));

                chart.draw(data, options);
            })
            .catch((error) => {
                if (error.response) {
                    this.errors.code = error.response.status;
                    this.errors.status = error.response.data.meta.code;
                    this.errors.headers = error.response.headers;
                }
            })
        }
    },
}
</script>
